import { QueryClient, useQuery } from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import { getSqrTokenPrice, getToken, getTokenChart, GetTokenSearchParams, TokenPeriod } from '@api';

const tokenQueryKeys = {
  token: (tokenId: string, params: GetTokenSearchParams = {}) => ['token', tokenId, params],
  tokenChart: (tokenId: string, params: GetTokenSearchParams = {}) => [
    'tokenChart',
    tokenId,
    params,
  ],
  sqrTokenPrice: ['sqrTokenPrice'],
};

export const useTokenQuery = (
  params: {
    tokenId: string;
    searchParams?: GetTokenSearchParams;
  },
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<ReturnType<typeof getToken>>, unknown, unknown, any>,
    'enabled' | 'retry'
  >,
) => {
  return useQuery({
    queryKey: tokenQueryKeys.token(params.tokenId, params.searchParams),
    queryFn: () =>
      getToken(params.tokenId, {
        searchParams: params.searchParams,
      }),
    ...options,
  });
};

/**
 * @deprecated
 * This method is deprecated, please use useTokenChartQuery
 */
export const useTokenChartQueryDeprecated = (
  params: {
    tokenId: string;
    searchParams?: GetTokenSearchParams;
  },
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTokenChart>>, unknown, unknown, any>,
    'enabled'
  >,
) => {
  const { tokenId, searchParams } = params;

  return useQuery({
    queryKey: tokenQueryKeys.tokenChart(tokenId, searchParams),
    queryFn: () =>
      getTokenChart(tokenId, {
        searchParams,
      }),
    ...options,
  });
};

export const useTokenChartQuery = (params: { tokenId: string; period: TokenPeriod }) => {
  const { tokenId, period } = params;

  return useQuery({
    queryKey: tokenQueryKeys.tokenChart(tokenId, { period }),
    queryFn: async () => {
      const response = await getTokenChart(tokenId, {
        searchParams: { period },
      });

      const { prices } = response;

      return (
        prices?.map((el) => ({
          price: el.attributes.price,
          timestamp: el.attributes.time,
        })) || []
      );
    },
  });
};

export const prefetchTokenQuery = (
  clientQuery: QueryClient,
  tokenId: string,
  options?: Parameters<typeof getToken>[1],
) => {
  return clientQuery.prefetchQuery({
    queryKey: tokenQueryKeys.token(tokenId, options?.searchParams),
    queryFn: () => getToken(tokenId, options),
  });
};

export const useSqrTokenPriceQuery = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getSqrTokenPrice>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'refetchInterval'
  >,
) => {
  return useQuery({
    queryKey: tokenQueryKeys.sqrTokenPrice,
    queryFn: () => getSqrTokenPrice(),
    ...options,
  });
};

export const prefetchSqrTokenPriceQuery = (
  clientQuery: QueryClient,
  options?: Parameters<typeof getSqrTokenPrice>[0],
) => {
  return clientQuery.prefetchQuery({
    queryKey: tokenQueryKeys.sqrTokenPrice,
    queryFn: () => getSqrTokenPrice(options),
  });
};
